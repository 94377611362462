// import lazyLoading from '../lazyLoading';

export default {
  name: 'tutorials',
  caption: 'Tutorials',
  wdm16_id: '18313',
  icon: 'icon-settings',
  path: '/tutorials',
  // component: lazyLoading('patient/index'),
  required: [],
  permissions: [],
  meta: {
    required: [],
    icon: 'fa-bars',
    expanded: false
  }
};
