import Vue from 'vue';
import { API_BASE_URL } from '@/config';
export default {
  send_message_to_this_user (data) {
    return Vue.http.post(API_BASE_URL + 'messenger/send_message_to_this_user', data);
  },
  see_this_user_messages (data) {
    return Vue.http.post(API_BASE_URL + 'messenger/see_this_user_messages', data);
  },
  prepare_polestar_project_to_talk (data) {
    return Vue.http.post(API_BASE_URL + 'messenger/prepare_polestar_project_to_talk', data);
  },
  get_user_messenger_list () {
    return Vue.http.get(API_BASE_URL + 'messenger/get_user_messenger_list');
  },
  get_messages_list (data) {
    return Vue.http.post(API_BASE_URL + 'messenger/get_messages_list', data);
  },
  save_meeting (data) {
    return Vue.http.post(API_BASE_URL + 'messenger/save_meeting', data);
  },
  join_meeting (data) {
    return Vue.http.post(API_BASE_URL + 'messenger/join_meeting', data);
  },
  meeting_share (data) {
    return Vue.http.put(API_BASE_URL + 'messenger/meeting_share', data);
  },
  leave_meeting (data) {
    return Vue.http.put(API_BASE_URL + 'messenger/leave_meeting', data);
  },
  get_meeting_data (data) {
    return Vue.http.post(API_BASE_URL + 'messenger/get_meeting_data', data);
  },
  save_messenger_group (data) {
    return Vue.http.post(API_BASE_URL + 'messenger/save_messenger_group', data);
  },
  get_members_for_messenger_group () {
    return Vue.http.get(API_BASE_URL + 'messenger/get_members_for_messenger_group');
  }
};
