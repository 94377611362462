// import lazyLoading from '../lazyLoading';

export default {
  name: 'technical',
  caption: 'technical',
  wdm16_id: '18315',
  icon: 'icon-settings',
  path: '/technical',
  // component: lazyLoading('patient/index'),
  required: [],
  permissions: [],
  meta: {
    required: [],
    icon: 'fa-bars',
    expanded: false
  }
};
