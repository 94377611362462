import MessengerService from "../messenger";
import store from '../../store';

class ClsMessenger {
  // user settings son hali authdaki user dökümanına güncelleniyor.
  static get_messenger_data = function() {
    MessengerService.get_user_messenger_list()
      .then(resp => {
        if (resp.data.status === 'success') {
          // let ai_robot_det = false;
          /*
          for (let k in resp.data.result) {
            if (resp.data.result[k].user && resp.data.result[k].user.username === 'airobot_drwisdom') {
              // ai_robot_det = true;
              break;
            }
          }
          if (!ai_robot_det) {
            let ai_robot = {
              'user': {
                'ai_robot': 1,
                'department': 'wisdomera',
                'first_name': 'Dr',
                'last_name': 'Wisdom',
                'username': 'airobot_drwisdom',
                'hospital_id': 'wis',
                'hospital_group': 'wisdomera'
              },
              'message': [{ 'date': '2019-11-12T11:47:49.635000', 'status': 'wait', 'message': 'Merhabalar nasılsınız. Size nasıl yardımcı olabilirim ? ', 'sender': 'drwisdom' }]
            };
            resp.data.result.splice(0, 0, ai_robot);
          }
          */
          store.commit('messenger_data', resp.data.result);
        } else {
          store.commit('messenger_data', []);
          console.log('error', resp.data.message);
        }
      });
  }

  static f_getMeetingData = function(id) {
    let data = { 'id': id };
    MessengerService.get_meeting_data(data)
      .then(resp => {
        if (resp.data.status === 'success') {
          if (resp.data.result.wdmr_69) {
            // console.log(resp.data.result.wdmr_69);
            localStorage.setItem('meeting', JSON.stringify(resp.data.result.wdmr_69));
          } else {
            localStorage.removeItem('meeting');
          }
        } else {
          console.log('error', resp.data.message);
        }
      });
  }

};

export { ClsMessenger };
