import * as types from '../../mutation-types';
// import smsprovider from './routes/smsprovider';
import projectlist from './routes/projectlist';
import report_list from './routes/report_list';
import rowanalyzebyid from './routes/rowanalyzebyid';
import reportmoduleiframe from './routes/reportmoduleiframe';
import projectlistdetailed from './routes/projectlistdetailed';
import technical from './routes/technical';
import contact from './routes/contact';
import terminology from './routes/terminology';
import tutorials from './routes/tutorials';

const state = {
  items: [
    projectlist,
    report_list,
    rowanalyzebyid,
    reportmoduleiframe,
    projectlistdetailed,
    technical,
    tutorials,
    // smsprovider,
    terminology,
    contact
  ]
};

const mutations = {
  [types.EXPAND_MENU] (state, menuItem) {
    if (menuItem.index > -1) {
      if (state.items[menuItem.index] && state.items[menuItem.index].meta) {
        state.items[menuItem.index].meta.expanded = menuItem.expanded;
      }
    } else if (menuItem.item && 'expanded' in menuItem.item.meta) {
      menuItem.item.meta.expanded = menuItem.expanded;
    }
  }
};

export default {
  state,
  mutations
};
