// import lazyLoading from '../lazyLoading';

export default {
  name: 'projectlist',
  caption: 'my projects',
  wdm16_id: '18316',
  icon: 'icon-pencil',
  path: '/projectlist',
  // component: lazyLoading('patient/index'),
  required: ['logined'],
  permissions: [],
  meta: {
    required: [],
    icon: 'fa-bars',
    expanded: false
  }
};