// import lazyLoading from '../lazyLoading';

export default {
  name: 'projectlistdetailed',
  caption: 'projelerim (detaylı)',
  wdm16_id: '',
  icon: 'icon-pencil',
  path: '/projects/detailed',
  // component: lazyLoading('patient/index'),
  required: ['logined'],
  permissions: [],
  meta: {
    required: [],
    icon: 'fa-bars',
    expanded: false
  }
};